import { action, computed, observable, runInAction, when, toJS } from 'mobx';
import { EventBus } from '@vtblife/event-bus';
import { AppContextChanged, AppEnvironment, Config } from '@vtblife/event-bus-events';
import { reportErrorToSentry } from '@vtblife/layout-components/shell/utils';
import { MicroAppsConfig } from '@vtblife/layout-config/types';

import { isBrowser } from '@vtblife/layout-components/utils/is-browser';

import { parseRegionalPathname } from '@vtblife/layout-utils';

import { Recaptcha } from '../models/config';
import { RootStore } from './root';
import { clientConfigApiService } from '../services/client-config-api';

export class ConfigStore {
    private readonly eventBus = EventBus.getInstance();
    private readonly rootStore: RootStore;

    @observable cdnUrl: string;
    @observable cdnBlocksPrefixUrl: string;
    @observable apiPrefix: string;
    @observable uploadPrefix: string;
    @observable backofficePrefix: string;
    @observable appEnvironment: AppEnvironment;
    //deprecated
    @observable recaptcha?: Recaptcha;
    @observable features?: FeaturesClientConfig;
    @observable abExperiments?: AbExperiments;
    @observable regionalLandingsConfig?: RegionalLandingsConfig;
    @observable ssrManifest: SSRManifest;
    @observable gatsbyManifest?: HtmlManifest;
    @observable supportPhone: string;
    @observable backupSupportPhone: string;
    @observable ITRequirements: string;
    @observable copyright: string;
    // shouldUpdateConfig - initialState is from server page response or build time in gatsby case, isn't from client request to api /get-client-config
    @observable shouldUpdateConfig?: boolean;
    @observable microAppsConfig: MicroAppsConfig;

    constructor(rootStore: RootStore, initialState?: InitialState['configStore']) {
        this.rootStore = rootStore;

        if (initialState) {
            this.setInitialState(initialState);
        }

        if (isBrowser()) {
            when(
                () => this.isInitialized,
                () => this.publishAppContextChanged(),
            );
        }
    }

    setInitialState(initialState: InitialState['configStore']) {
        Object.assign(this, initialState);
    }

    isFeatureAvailable = (featureName: string) => {
        if (!Array.isArray(toJS(this.features))) {
            reportErrorToSentry({
                error: new Error(`features aren't array`),
                extra: {
                    featureName,
                    features: this.features,
                    shouldUpdateConfig: this.shouldUpdateConfig,
                },
            });
            return false;
        }

        return this.features?.some?.((item) => item.name === featureName);
    };

    @computed get isInitialized() {
        const { headerStore, bundleTreeStore, layoutStore, sidebarStore } = this.rootStore;
        return (
            bundleTreeStore.isInitialized &&
            headerStore.isInitialized &&
            layoutStore.isInitialized &&
            sidebarStore.isInitialized
        );
    }

    @action
    async fetchConfig() {
        const { headerStore, footerStore, bundleTreeStore, layoutStore, sidebarStore } = this.rootStore;
        const clientConfig = await clientConfigApiService.loadClientConfig();

        runInAction(() => {
            this.shouldUpdateConfig = false;
            this.cdnUrl = clientConfig.cdnUrl;
            this.cdnBlocksPrefixUrl = clientConfig.cdnBlocksPrefixUrl;
            this.apiPrefix = clientConfig.apiPrefix;
            this.uploadPrefix = clientConfig.uploadPrefix;
            this.backofficePrefix = clientConfig.backofficePrefix;
            this.appEnvironment = clientConfig.appEnvironment;
            //deprecated
            this.recaptcha = clientConfig.recaptcha;
            this.features = clientConfig.features;
            this.gatsbyManifest = clientConfig.gatsbyManifest;
            this.supportPhone = clientConfig.supportPhone;
            this.backupSupportPhone = clientConfig.backupSupportPhone;
            this.ITRequirements = clientConfig.ITRequirements;
            this.copyright = clientConfig.copyright;
            this.abExperiments = clientConfig.abInfo?.experiments || {};
            this.regionalLandingsConfig = clientConfig.regionalLandingsConfig;
            this.microAppsConfig = clientConfig.microAppsConfig;

            headerStore.setHeader(clientConfig.header);
            footerStore.setFooter(clientConfig.footer);
            layoutStore.setLayoutConfig(clientConfig.layout);
            sidebarStore.setSidebar(clientConfig.sidebar);
            bundleTreeStore.setBundleTree(clientConfig.bundleTree);

            this.publishAppContextChanged();
        });
    }

    getApplicationPath(applicationName: string) {
        if (this.appEnvironment === 'production') {
            return `${this.cdnUrl}/assets/${applicationName}`;
        }

        return `${this.cdnUrl}/assets/${applicationName}/${this.appEnvironment}`;
    }

    getRegionalRedirect({ userRegionId, userRegionIdNarrow }: { userRegionId: number; userRegionIdNarrow?: number }) {
        if (!this.regionalLandingsConfig) {
            return null;
        }

        const { routeToAvailableCapitalsTree, availableCapitals } = this.regionalLandingsConfig;
        const { pathname, search } = this.rootStore.historyStore.location;
        const regionalLandingResult = parseRegionalPathname({
            pathname,
            regionalLandingRoutes: Object.keys(routeToAvailableCapitalsTree),
            regionNames: availableCapitals!,
        });

        if (!regionalLandingResult) {
            return null;
        }
        const { route } = regionalLandingResult;

        const capitalFromRegion =
            routeToAvailableCapitalsTree[route][userRegionId] ||
            (userRegionIdNarrow && routeToAvailableCapitalsTree[route][userRegionIdNarrow]);

        if (capitalFromRegion) {
            return `${route}${search}`;
        }

        return null;
    }

    toJS(): Config {
        const {
            shouldUpdateConfig,
            appEnvironment,
            cdnUrl,
            cdnBlocksPrefixUrl,
            apiPrefix,
            uploadPrefix,
            backofficePrefix,
            recaptcha,
            supportPhone,
            backupSupportPhone,
            ITRequirements,
            copyright,
            microAppsConfig,
        } = this;

        return {
            shouldUpdateConfig,
            appEnvironment,
            cdnUrl,
            cdnBlocksPrefixUrl,
            recaptcha: {
                siteKey: recaptcha ? recaptcha.siteKey : '',
            },
            features: toJS(this.features) || [],
            abExperiments: toJS(this.abExperiments) || {},
            regionalLandingsConfig: toJS(this.regionalLandingsConfig) || {
                availableCapitals: [],
                routeToAvailableCapitalsTree: {},
            },
            apiPrefix,
            uploadPrefix,
            backofficePrefix,
            supportPhone,
            backupSupportPhone,
            ITRequirements,
            copyright,
            yandexCaptcha: toJS(microAppsConfig.yandexCaptcha),
        };
    }

    private publishAppContextChanged(): void {
        const event: AppContextChanged = {
            type: 'app:contextChanged',
            data: {
                authInfo: this.rootStore.userStore.toJS(),
                config: this.toJS(),
                utm: this.rootStore.utmStore.utm,
                bundleTree: this.rootStore.bundleTreeStore.toJS(),
            },
            category: 'behavior',
        };
        this.eventBus.publish(event);
    }
}

import React, { useContext } from 'react';
import { Config, ShowReportModalPayload, User } from '@vtblife/event-bus-events';
import { UpdatesData, UpdatingServices } from '@vtblife/layout-config/types';

import { Workbox } from 'workbox-window';

import { Survey } from '../hooks/use-survey';

export interface AuthorizeContext {
    user?: User | null;
    isAuthorized?: boolean;
    verification?: {
        isAlreadyPassedM2ProVerification?: boolean;
    };
}

export interface SurveyContext {
    currentSurvey?: Survey;
    closeCurrentSurvey?: () => void;
}

export interface UserRegionContext {
    userRegionDetectedId?: number;
    userRegionSelectedId?: number;
    userRegionSelectedIdNarrow?: number;
    userRegionName?: string;
    userRegionId?: number;
    activePartner?: string;
    isDetectRegionModalVisible?: boolean;
    isSelectRegionModalVisible?: boolean;
    callInitEvent?: () => void;
    fetchUserRegion?: () => void;
    setUserRegion?: (arg: any) => void;
    updateUserRegion?: (arg: any) => void;
    showDetectRegionModal?: () => void;
    hideDetectRegionModal?: () => void;
    showSelectRegionModal?: () => void;
    hideSelectRegionModal?: () => void;
}

export type ConfigContext = Partial<Config>;

export type ReportModalContext = Partial<ShowReportModalPayload> & {
    isOpened?: boolean;
};

export type UpdatesContext = {
    servicesUpdates?: UpdatesData;
    refresh?: (services?: UpdatingServices[]) => void;
};

export type ServiceWorkerContext = {
    workbox?: Workbox;
};

export interface ShellContext {
    survey: SurveyContext;
    reportModal: ReportModalContext;
    updates: UpdatesContext;
    serviceWorker: ServiceWorkerContext;
}

export const ShellContextValue = React.createContext<ShellContext>({
    survey: {},
    reportModal: {},
    updates: {},
    serviceWorker: {},
});

export interface ShellAuthorizeContext {
    authorize: AuthorizeContext;
}

export const ShellAuthorizeContextValue = React.createContext<ShellAuthorizeContext>({
    authorize: {},
});

export interface ShellUserRegionContext {
    userRegion: UserRegionContext;
}

export const ShellUserRegionContextValue = React.createContext<ShellUserRegionContext>({
    userRegion: {},
});

export interface ShellConfigContext {
    config: ConfigContext;
}

export const ShellConfigContextValue = React.createContext<ShellConfigContext>({
    config: {},
});

export const useAuthorizeContext = () => useContext(ShellAuthorizeContextValue).authorize;
export const useSurveyContext = () => useContext(ShellContextValue).survey;
export const useUserRegionContext = () => useContext(ShellUserRegionContextValue).userRegion;
export const useConfigContext = () => useContext(ShellConfigContextValue).config;
export const useReportModalContext = () => useContext(ShellContextValue).reportModal;
export const useUpdatesContext = () => useContext(ShellContextValue).updates;
export const useServiceUpdatesContext = (service: UpdatingServices) =>
    useContext(ShellContextValue).updates?.servicesUpdates?.[service];
export const useShellContext = () => useContext<ShellContext>(ShellContextValue);
export const useServiceWorkerContext = () => useContext(ShellContextValue).serviceWorker;

// auto generated in scripts/sync-initial-stats.ts, don't change manually

export const ekbConfig = {
    header: {
        items: [
            {
                id: 'DEFAULT',
                displayName: 'Частным лицам',
                path: '/ekaterinburg/',
                dropdownMenu: [
                    {
                        id: '6759450a852e8ac9d214a6bb',
                        displayName: 'Новостройки',
                        url: '/ekaterinburg/novostroyki/',
                        icon: 'm2-magnifier',
                    },
                    {
                        id: '6345648ac4af4b264e895b89',
                        displayName: 'Ипотека',
                        icon: 'm2-percentage-circle',
                        exact: true,
                        dropdownMenu: [
                            {
                                id: '64e8ad903ebc9e513bbf6d5f',
                                displayName: 'Подать заявку',
                                path: '/ipoteka/',
                                icon: '',
                                exact: true,
                            },
                            {
                                id: '64e8ada43ebc9e513bbf6d60',
                                displayName: 'Ипотечный калькулятор',
                                path: '/ipoteka/calculator/',
                                icon: '',
                            },
                            {
                                id: '65705a315216331f9d89bd97',
                                displayName: 'Ипотека на строительство дома',
                                path: '/ipoteka/stroitelstvo-doma/',
                                icon: '',
                                exact: true,
                            },
                            {
                                id: '657059a15216331f9d89bd90',
                                displayName: 'Семейная ипотека',
                                path: '/ipoteka/semeinaya-ipoteka/',
                                icon: '',
                                exact: true,
                            },
                            {
                                id: '657059b35216331f9d89bd91',
                                displayName: 'Рефинансирование ипотеки',
                                path: '/ipoteka/refinansirovanie-ipoteki/',
                                icon: '',
                                exact: true,
                            },
                            {
                                id: '657059d95216331f9d89bd94',
                                displayName: 'Ипотека с господдержкой',
                                path: '/ipoteka/lgotnaya-s-gospodderzhkoy/',
                                icon: '',
                                exact: true,
                            },
                            {
                                id: '657059fa5216331f9d89bd95',
                                displayName: 'Ипотека на первичное жильё',
                                path: '/ipoteka/na-zhile-na-pervichnom-rynke/',
                                icon: '',
                                exact: true,
                            },
                            {
                                id: '65705a1a5216331f9d89bd96',
                                displayName: 'Ипотека на вторичное жильё',
                                path: '/ipoteka/na-zhile-na-vtorichnom-rynke/',
                                icon: '',
                                exact: true,
                            },
                        ],
                    },
                    {
                        id: '6345651dc4af4b264e895b8c',
                        displayName: 'Сделка',
                        path: '/services/deal/',
                        icon: 'house-plus',
                    },
                    {
                        id: '669a480e4798dc4baa83f4a5',
                        displayName: 'Проверка',
                        path: '/services/proverka-yuridicheskoy-chistoty-kvartiry/',
                        icon: '',
                    },
                    {
                        id: '669a48614798dc4baa83f4ac',
                        displayName: 'Защита сделки',
                        path: '/services/guaranteed-deal/',
                        icon: '',
                    },
                ],
            },
            {
                id: 'REALTORS',
                displayName: 'Риелторам и агентствам',
                path: '/rieltoram/',
                icon: '',
                dropdownMenu: [
                    {
                        id: '67594246852e8ac9d214a4fd',
                        displayName: 'Новостройки',
                        url: '/ekaterinburg/novostroyki/',
                        icon: 'm2-magnifier',
                    },
                    {
                        id: '634569648a92e72824a8807a',
                        displayName: 'Ипотека',
                        path: '/rieltoram/ipoteka/',
                        icon: 'm2-percentage-circle',
                    },
                    {
                        id: '634569838a92e72824a8807c',
                        displayName: 'Сделка',
                        path: '/rieltoram/online-sdelka/',
                        icon: 'note-sign',
                    },
                    {
                        id: '6345699b8a92e72824a8807e',
                        displayName: 'Сервисы',
                        icon: 'm2-star',
                        dropdownMenu: [
                            {
                                id: '634569ac8a92e72824a88080',
                                displayName: 'Сервис безопасных расчётов',
                                path: '/rieltoram/sbr/',
                                icon: '',
                            },
                            {
                                id: '634569bd8a92e72824a88082',
                                displayName: 'Электронная регистрация',
                                path: '/rieltoram/elreg/',
                                icon: '',
                            },
                            {
                                id: '634569d68a92e72824a88084',
                                displayName: 'Защита сделки',
                                path: '/rieltoram/guaranteed-deal/',
                                icon: '',
                            },
                            {
                                id: '634569f58a92e72824a88086',
                                displayName: 'M2Pro Новостройки',
                                path: '/rieltoram/m2pro-novostroyki/',
                                icon: '',
                            },
                            {
                                id: '6447cc6217809f0048b89a1f',
                                displayName: 'Зарубежная недвижимость',
                                path: '/rieltoram/zarubezhom/',
                                icon: '',
                            },
                            {
                                id: '63456a148a92e72824a88088',
                                displayName: 'Проверка недвижимости',
                                path: '/rieltoram/proverka/',
                                icon: '',
                            },
                        ],
                    },
                    {
                        id: '63456a3e8a92e72824a8808a',
                        displayName: 'Обучение',
                        icon: 'calendar',
                        dropdownMenu: [
                            {
                                id: '668fef213dd22e52f8ecc8a1',
                                displayName: 'База знаний',
                                path: '/rieltoram/baza-znanij/',
                                icon: '',
                            },
                            { id: '668feeb43dd22e52f8ecc88c', displayName: 'Мероприятия', path: '/events/', icon: '' },
                            {
                                id: '66fa99bb335f3685d8e5a933',
                                displayName: 'Тренинги',
                                path: '/rieltoram/online-training/',
                                icon: '',
                            },
                            {
                                id: '676ad2427b8ad3e5328f2f86',
                                displayName: 'Курс: ипотека',
                                path: '/rieltoram/kurs-ipoteka/',
                                icon: '',
                            },
                            {
                                id: '676ad27e7b8ad3e5328f2fb6',
                                displayName: 'Курс: налоги и законы',
                                path: '/rieltoram/kurs-nalogi-i-zakony/',
                                icon: '',
                            },
                            {
                                id: '676ad2ba7b8ad3e5328f2ff3',
                                displayName: 'Курс: новостройки',
                                path: '/rieltoram/kurs-novostroiki/',
                                icon: '',
                            },
                        ],
                    },
                    { id: 'rieltoram-rating', displayName: 'Рейтинг агентств', path: '/rieltoram/rating/', icon: '' },
                ],
            },
            {
                id: 'PARTNERS',
                displayName: 'Партнёрам',
                path: '/partners/',
                icon: '',
                dropdownMenu: [
                    { id: '63456b048a92e72824a88095', displayName: 'Наши партнёры', path: '/partners/', icon: '' },
                    {
                        id: '646340fecd286f0048fb0868',
                        displayName: 'Амбассадоры',
                        url: 'https://am2ssador.m2.ru/',
                        icon: 'lightning-circle',
                        target: '_blank',
                    },
                ],
            },
        ],
        partners: {
            samolet: {
                id: 'samolet',
                logo: 'https://cdn.m2.ru/assets/file-upload-server/79d4b22c91a9e7476efcd27c1c19305a.svg\t',
                logoWhite: 'https://cdn.m2.ru/assets/file-upload-server/f56694b65eee2c805b5f384aa029a70a.svg\t',
                url: '/samolet/?erid=sKjnGhd4KprWDTakBBthFWQpQEht771BNCSQFPFNiSNzUkK',
                regionIds: [9513, 3, 108],
                analyticsDataClick: 'msk-special-project-logo-click',
            },
        },
        personalButtons: {},
        loginPaths: {
            default: { id: 'default-login', url: '/login/' },
            client: { id: 'login-client', url: '/login/client/' },
            professional: { id: 'login-professional', url: '/login/professional/' },
            partner: { url: '/login/partner/', id: 'login-partner' },
        },
        logoHref: '/ekaterinburg/',
    },
    footer: {
        downloadAppQrCode: 'https://cdn.m2.ru/assets/file-upload-server/29f57c4c51c7f8de5cc93a614d562e0e.svg',
        socialLinks: [
            { name: 'ВКонтакте', icon: 'vk', href: 'https://vk.com/metr_kvadratnyy' },
            { name: 'Telegram', icon: 'telegram', href: 'https://t.me/metrkvadratny/' },
        ],
        middleLinks: [
            {
                title: 'Частным лицам',
                links: [
                    {
                        name: 'Новостройки',
                        href: '/ekaterinburg/novostroyki/',
                        linkGenerationId: 'new-buildings',
                        analytics: 'footerLinkNovostroyki',
                    },
                    { name: 'Ипотека', to: '/ipoteka/', analytics: 'footerLinkIpoteka' },
                    {
                        name: 'Ипотечный калькулятор',
                        to: '/ipoteka/calculator/',
                        analytics: 'footerLinkIpotechyjKalkulyator',
                    },
                    { name: 'Сделка', to: '/services/deal/', analytics: 'footerDeal' },
                    { name: 'Защита сделки', to: '/services/guaranteed-deal/', analytics: 'footerGuaranteedDeal' },
                    {
                        name: 'Проверка недвижимости',
                        to: '/services/proverka-yuridicheskoy-chistoty-kvartiry/',
                        analytics: 'footerLinkProverka',
                    },
                    { name: 'Популярное', to: '/poleznoe/', analytics: 'footerPopular' },
                ],
            },
            {
                title: 'Риелторам и агентствам',
                links: [
                    {
                        name: 'Новостройки',
                        href: '/ekaterinburg/novostroyki/',
                        linkGenerationId: 'new-buildings',
                        analytics: 'footerLinkNovostroyki',
                    },
                    { name: 'Ипотека', to: '/rieltoram/ipoteka/' },
                    { name: 'Сделка', to: '/rieltoram/online-sdelka/' },
                    { name: 'Сервис безопасных расчётов', to: '/rieltoram/sbr/' },
                    { name: 'Электронная регистрация', to: '/rieltoram/elreg/' },
                    { name: 'Защита сделки', to: '/rieltoram/guaranteed-deal/' },
                    { name: 'Проверка недвижимости', to: '/rieltoram/proverka/', analytics: 'footerLinkProverka' },
                    { name: 'Мероприятия', to: '/events/' },
                    { name: 'M2Pro Новостройки', to: '/rieltoram/bonus-novostroyki/' },
                ],
            },
        ],
        top: [
            [
                { name: 'О компании', to: '/about/', analytics: 'footerLinkAbout' },
                { name: 'Вакансии', to: '/vacancies/', analytics: 'footerLinkVacancies' },
                { name: 'ИТ-деятельность', to: '/it/', analytics: 'footerLinkIT' },
                { name: 'Помощь', to: '/support/', analytics: 'footerLinkSupport' },
                { name: 'Наши партнёры', to: '/partners/', analytics: 'footerLinkPartners' },
                { name: 'М2 Медиа', href: '/media/', analytics: 'footerLinkNews' },
            ],
            [
                {
                    name: 'Правила оплаты',
                    href: '/assets/pdfs/oplata-onlain.pdf',
                    cdn: true,
                    analytics: 'footerLinkPaymentRules',
                },
                {
                    name: 'Закупки',
                    href: 'https://bidzaar.com/publicprofile/index/54eee136-f189-4823-8b5c-5dc58589d1d0',
                    target: '_blank',
                    analytics: 'footerLinkTender',
                },
                { name: 'Клиентские документы', href: '/doc/clients/', analytics: 'footerLinkSoglasheniya' },
            ],
        ],
        middle: [
            {
                title: 'Поиск',
                links: [{ name: 'Новостройки', href: '/moskva/novostroyki/', analytics: 'footerLinkNovostroyki' }],
            },
            {
                title: 'Ипотека',
                links: [{ name: 'Заявка на ипотеку', to: '/ipoteka/', analytics: 'footerLinkIpoteka' }],
            },
            {
                title: 'Сервисы',
                links: [
                    { name: 'Сделка', to: '/services/deal/', analytics: 'footerDeal' },
                    { name: 'Защита сделки', to: '/services/guaranteed-deal/', analytics: 'footerGuaranteedDeal' },
                    {
                        name: 'Проверка недвижимости',
                        to: '/services/proverka-yuridicheskoy-chistoty-kvartiry/',
                        analytics: 'footerLinkProverka',
                    },
                ],
            },
            { title: 'Ремонт', links: [{ name: 'Ремонт', to: '/remont/', analytics: 'footerLinkRemont' }] },
        ],
        logoHref: '/ekaterinburg/',
    },
};

import React from 'react';
import { HeaderTabItem } from '@vtblife/layout-config/types';

import { DropdownMenu } from '../dropdown-menu/dropdown-menu';
import { MenuItem } from '../menu-item/menu-item';
import { sendAnalyticsEvent } from '../../analytics/send-analytics-event';
import { useActiveTab } from '../hooks';

export interface MenuItemProps {
    pathname?: string;
    item: HeaderTabItem & {
        freshCount?: number;
    };
    isMainMenu?: boolean; // Логика отображения главного меню в мобиле несколько отличается от меню авторизованного пользователя

    isOpenByDefault: boolean; // Раскрывать ли пункт меню в начальном состоянии
    onClick?: () => void;
    event?: string;
    dataTest?: string;
}

export const MenuLink: React.FC<MenuItemProps> = ({
    item,
    pathname,
    isMainMenu,
    onClick,
    isOpenByDefault,
    event,
    dataTest,
}) => {
    const activeTab = useActiveTab();
    if (item.dropdownMenu) {
        return (
            <DropdownMenu
                dataTest={dataTest}
                item={item}
                pathname={pathname}
                isMainMenu={isMainMenu}
                onSubItemClick={onClick}
                isOpenByDefault={isOpenByDefault}
                event={event || 'header_click'}
            />
        );
    }

    const onClickWithAnalytics = () => {
        if (!item.isSidebarEl) {
            sendAnalyticsEvent({
                event: event || 'header_click',
                user_type: activeTab?.displayName,
                product_type: item.displayName,
            });
        }
        onClick?.();
    };

    return (
        <MenuItem
            dataTest={dataTest}
            item={item}
            pathname={pathname}
            isMainMenu={isMainMenu}
            onClick={onClickWithAnalytics}
        />
    );
};

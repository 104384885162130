// auto generated in scripts/sync-initial-stats.ts, don't change manually

export const initialState = {
    userStore: { user: null, initialized: false },
    abContentSourcePath: '',
    configStore: {
        shouldUpdateConfig: true,
        cdnUrl: 'https://cdn.m2.ru',
        cdnBlocksPrefixUrl: 'https://cdn.m2.ru/assets/cms-data/json-master',
        appEnvironment: 'test',
        recaptcha: { siteKey: '6Lf3NMYUAAAAAOBNTKj3QV6NEVkMgF_Xe-C4eO1T' },
        features: [
            { name: 'remont-contest-reseller-leaderboard' },
            { name: 'remont-contractor-order-page' },
            { name: 'dc-alternative-birth-certificate' },
            { name: 'personal-area-verification-feed' },
            { name: 'sbr-linked-deals-tab' },
            { name: 'personal-area-company-verification' },
            { name: 'contract-kit-approve-by-bank' },
            { name: 'lk-management-redirect' },
            { name: 'auth-is-email-verification-required-prof' },
            { name: 'auth-is-advertising-agreement-required' },
            { name: 'lk-management-company-settings-security-sessions' },
            { name: 'lk-management-draft-companies-list' },
            { name: 'lk-management-developer-tariff-read' },
            { name: 'mortgage-management' },
            { name: 'mortgage-calculator-v2' },
            { name: 'personal-area-samolet-banner' },
            { name: 'lk-phone-modal' },
            { name: 'dc-deal-navigation' },
            { name: 'guaranteed-deal-short-order-flow' },
            { name: 'm2pro-verification-available' },
            { name: 'alternative-electronic-mortgage-available' },
            { name: 'alternative-ddi-feature-available' },
            { name: 'personal-area-partner-modal' },
            { name: 'vtb-login' },
            { name: 'alternative-suburban-deals' },
            { name: 'yandex-captcha' },
            { name: 'dc-estate-approval' },
            { name: 'dc-estate-approval-auto-check' },
            { name: 'show-helpdesk-web-push' },
            { name: 'dc-widget-deal-meeting' },
        ],
        supportPhone: '+7 495 136‑28‑18',
        copyright:
            '© Метр квадратный, 2024. М2 — экосистема для поиска и покупки недвижимости, выбора ипотечных предложений, защиты и проведения сделки. Общество с ограниченной ответственностью «Экосистема недвижимости «Метр квадратный», ОГРН 1197746330132 Адрес: 127055, г. Москва, вн. тер. г. муниципальный округ Тверской, ул. Лесная, д. 43',
        backupSupportPhone: '+7 495 230‑00‑21',
        microAppsConfig: {
            yandexCaptcha: { clientKey: 'ysc1_ZCwu1WZhXtFRUBtDs1VJGA1YHYHcsIJqChyneOSK72da6e81' },
            applications: [
                {
                    applicationName: 'deal-online',
                    logger: { sentryDsn: ['https://0d1f6f1647294084bc954d53a2d393b1@sentry.m2.ru/205'] },
                },
                {
                    applicationName: 'deal-join',
                    logger: { sentryDsn: ['https://0d1f6f1647294084bc954d53a2d393b1@sentry.m2.ru/205'] },
                },
                {
                    applicationName: 'deal-landing',
                    logger: { sentryDsn: ['https://0d1f6f1647294084bc954d53a2d393b1@sentry.m2.ru/205'] },
                },
                {
                    applicationName: 'widget-deal-meeting',
                    logger: { sentryDsn: ['https://0d1f6f1647294084bc954d53a2d393b1@sentry.m2.ru/205'] },
                },
                {
                    applicationName: 'deal-management',
                    logger: { sentryDsn: ['https://0d1f6f1647294084bc954d53a2d393b1@sentry.m2.ru/205'] },
                },
                {
                    applicationName: 'm2pro',
                    logger: { sentryDsn: ['https://60c01d21118544e6be59448fc48b1376@sentry.m2.ru/175'] },
                },
                {
                    applicationName: 'personal-area',
                    logger: { sentryDsn: ['https://831865f4b39e404aa2dcb7e030e11f36@sentry.m2.ru/11'] },
                },
                {
                    applicationName: 'root',
                    logger: { sentryDsn: ['https://5d621caae0bd4476b6af418ed711758d@sentry.m2.ru/2'] },
                },
                {
                    applicationName: 'alternative',
                    logger: { sentryDsn: ['https://44e054afb82c414db6d51f33244e8312@sentry.m2.ru/113'] },
                },
                {
                    applicationName: 'deal-navigation',
                    logger: { sentryDsn: ['https://e0d91495142b458e8e9219cb1f8e526e@sentry.m2.ru/223'] },
                },
                {
                    applicationName: 'apartment-complex',
                    logger: { sentryDsn: ['https://4c0fe13557c64f38b6f20db0d4632b93@sentry.m2.ru/166'] },
                },
                {
                    applicationName: 'auth',
                    logger: { sentryDsn: ['https://8822e70a08e24189bd8f3d817cc70fd6@sentry.m2.ru/4'] },
                },
                {
                    applicationName: 'backstage',
                    logger: { sentryDsn: ['https://352803b5b6574b80ade9ac7776a03f02@sentry.m2.ru/101'] },
                },
                {
                    applicationName: 'billing',
                    logger: { sentryDsn: ['https://8adc9f776c1d4cfeaae2b2b12598b997@sentry.m2.ru/54'] },
                },
                {
                    applicationName: 'Re',
                    logger: { sentryDsn: ['https://767da377bc8949958120e5758beff2fa@sentry.m2.ru/128'] },
                },
                {
                    applicationName: 'contract-kit',
                    logger: { sentryDsn: ['https://8ae951f815b549aaa25d21962d548b8a@sentry.m2.ru/117'] },
                },
                {
                    applicationName: 'document-configurator',
                    logger: { sentryDsn: ['https://cfe7e411f9b94c3f96f16e208e1154f6@sentry.m2.ru/15'] },
                },
                {
                    applicationName: 'eregistration',
                    logger: { sentryDsn: ['https://7cb462d17d5242df890bcace0b8fb8c2@sentry.m2.ru/13'] },
                },
                {
                    applicationName: 'home',
                    logger: { sentryDsn: ['https://1dfe3535ce164dc2bc61c3ea8f17e456@sentry.m2.ru/8'] },
                },
                {
                    applicationName: 'individuals',
                    logger: { sentryDsn: ['https://926d71e9893b499d96f0f0c1ac0a6834@sentry.m2.ru/40'] },
                },
                {
                    applicationName: 'lk-management',
                    logger: { sentryDsn: ['https://555a9bd4f11c4b1eb88f74a7b4cfc96d@sentry.m2.ru/98'] },
                },
                {
                    applicationName: 'm2pro-commission',
                    logger: { sentryDsn: ['https://b80fb0b8747b49e096fed2bd8c8815e0@sentry.m2.ru/214'] },
                },
                {
                    applicationName: 'm2-pro-presentations',
                    logger: { sentryDsn: ['https://a7145ece9f2f43da9bd9727998d83aad@sentry.m2.ru/201'] },
                },
                {
                    applicationName: 'mortgage-front',
                    logger: { sentryDsn: ['https://6d751bd57b0941bab3cab85b97cef3d5@sentry.m2.ru/6'] },
                },
                {
                    applicationName: 'news',
                    logger: { sentryDsn: ['https://9866db9260da464292d753739c67d11b@sentry.m2.ru/112'] },
                },
                {
                    applicationName: 'partners-public',
                    logger: { sentryDsn: ['https://0a37234052a1487d8d534f9e5b402204@sentry.m2.ru/33'] },
                },
                {
                    applicationName: 'property-insurance',
                    logger: { sentryDsn: ['https://94228088746d4efa97e7f3e6b9be6a9d@sentry.m2.ru/35'] },
                },
                {
                    applicationName: 'real-estate-registry',
                    logger: { sentryDsn: ['https://0fd91fd118dc4f01ba23c199edade1e8@sentry.m2.ru/21'] },
                },
                {
                    applicationName: 'realty-appraisal',
                    logger: { sentryDsn: ['https://cd1026daca3f4945b6cbbbc4521762f7@sentry.m2.ru/14'] },
                },
                {
                    applicationName: 'remont-contest',
                    logger: { sentryDsn: ['https://33e67e1b9bf24829bf905e3a93d67ac3@sentry.m2.ru/193'] },
                },
                {
                    applicationName: 'remont',
                    logger: { sentryDsn: ['https://c8634690725e4f529942af556a365c46@sentry.m2.ru/19'] },
                },
                {
                    applicationName: 'remont-public',
                    logger: { sentryDsn: ['https://84f8a0353cda413eb1c2a305b876f2b9@sentry.m2.ru/146'] },
                },
                {
                    applicationName: 'sbr',
                    logger: { sentryDsn: ['https://10aeba3daa1044a3bc98f391fe3e7a3e@sentry.m2.ru/17'] },
                },
                {
                    applicationName: 'suburban',
                    logger: { sentryDsn: ['https://79a5c6b3aee349d5ae66cfe4bbdb1254@sentry.m2.ru/247'] },
                },
                {
                    applicationName: 'suburban-catalog',
                    logger: { sentryDsn: ['https://db998689cdb64542a739a91f76b1d8c6@sentry.m2.ru/248'] },
                },
                {
                    applicationName: 'vacancy',
                    logger: { sentryDsn: ['https://60e7edaa510142038acb9c46e2fcb05e@sentry.m2.ru/9'] },
                },
                {
                    applicationName: 'chat',
                    logger: { sentryDsn: ['https://64565092a7324ddb947569678b1f2085@sentry.m2.ru/217'] },
                },
                {
                    applicationName: 'chat-templates',
                    logger: { sentryDsn: ['https://94e0cd8135f04e59b124769ede834228@sentry.m2.ru/253'] },
                },
            ],
        },
    },
    menuStore: {
        menu: {
            items: [
                {
                    id: '1',
                    displayName: 'Поиск',
                    dropdownMenu: [
                        {
                            id: '1',
                            displayName: 'Покупка недвижимости',
                            url: '/moskva/nedvizhimost/kupit-kvartiru/',
                            icon: 'm2-building',
                            iconColor: '#3216B5',
                        },
                        {
                            id: '2',
                            displayName: 'Аренда недвижимости',
                            url: '/moskva/nedvizhimost/snyat-kvartiru/',
                            icon: 'm2-chair',
                            iconColor: '#3216B5',
                        },
                        {
                            id: '3',
                            displayName: 'Новостройки',
                            url: '/moskva/novostroyki/',
                            icon: 'm2-building-multiple',
                            iconColor: '#3216B5',
                        },
                    ],
                },
                {
                    id: '11',
                    displayName: 'Ипотека',
                    path: '/ipoteka/',
                    icon: 'm2-percentage-circle',
                    iconColor: '#FF784B',
                },
                {
                    id: '2',
                    displayName: 'Сделка',
                    dropdownMenu: [
                        {
                            id: 'deal',
                            displayName: 'Покупка онлайн',
                            path: '/services/deal/',
                            icon: 'house-plus',
                            iconColor: '#B9B6C9',
                        },
                        {
                            id: '7',
                            displayName: 'Защита сделки',
                            path: '/services/guaranteed-deal/',
                            icon: 'm2-document-check',
                            iconColor: '#B9B6C9',
                        },
                        {
                            id: '1',
                            displayName: 'Проверка недвижимости',
                            path: '/services/proverka-yuridicheskoy-chistoty-kvartiry/',
                            icon: 'm2-house-checked',
                            iconColor: '#B9B6C9',
                        },
                        {
                            id: '2',
                            displayName: 'Расчёт стоимости недвижимости',
                            path: '/services/ocenka-stoimosti-kvartiry',
                            icon: 'm2-house-stroked',
                            iconColor: '#B9B6C9',
                        },
                        {
                            id: '3',
                            displayName: 'Страхование имущества',
                            path: '/services/strahovanie-imushchestva',
                            icon: 'shield-check',
                            iconColor: '#B9B6C9',
                        },
                    ],
                },
                {
                    id: '8',
                    displayName: 'Ремонт',
                    dropdownMenu: [
                        { id: '1', displayName: 'Ремонт под ключ', path: '/remont/' },
                        { id: '2', displayName: 'Предчистовая отделка', path: '/remont/white-box/' },
                        { id: '3', displayName: 'Дизайн-проект', path: '/remont/design-project/' },
                        { id: '4', displayName: 'Приемка квартиры', path: '/remont/priyemka-kvartiry/' },
                    ],
                },
                {
                    id: '3',
                    displayName: 'Партнёрам',
                    dropdownMenu: [
                        { id: '1', displayName: 'Агентам', path: '/rieltors' },
                        { id: '2', displayName: 'Застройщикам', path: '/developers' },
                    ],
                },
            ],
            partners: {
                default: {
                    id: 'samolet',
                    logo: 'https://cdn.m2.ru/assets/file-upload-server/79d4b22c91a9e7476efcd27c1c19305a.svg',
                    url: '/samolet/',
                },
                cds: {
                    id: 'cds',
                    logo: 'https://cdn.m2.ru/assets/file-upload-server/2967c9a3a2268d4067822c6edb678bb5.svg',
                    url: '/cds/',
                },
            },
        },
    },
    bundleTreeStore: {
        bundleTree: {
            routes: [
                { path: '/', exact: true, applicationName: 'home', availableForNotAuthorized: true },
                {
                    path: '(/login|/registration|/reset-password|/link-registration)',
                    applicationName: 'auth',
                    availableForNotAuthorized: true,
                },
                { path: '/mortgage', applicationName: 'mortgage', availableForNotAuthorized: true },
                { path: '/ipoteka', applicationName: 'mortgage', availableForNotAuthorized: true },
                { path: '/personal-area/services/guaranteed-deal', applicationName: 'guaranteed-deal' },
                {
                    path: '/personal-area/services/proverka-yuridicheskoy-chistoty-kvartiry',
                    applicationName: 'real-estate-registry',
                },
                {
                    path: '(/personal-area|/account|/employees|/affiliated-companies)',
                    applicationName: 'personal-area',
                },
                { path: '/management', applicationName: 'lk-management', availableForNotAuthorized: false },
                {
                    path: '/services/ocenka-stoimosti-kvartiry',
                    applicationName: 'realty-appraisal',
                    availableForNotAuthorized: true,
                },
                {
                    path: '/services/strahovanie-imushchestva',
                    applicationName: 'property-insurance',
                    availableForNotAuthorized: true,
                },
                {
                    path: '/services/payment-result',
                    applicationName: 'payment-result',
                    availableForNotAuthorized: true,
                },
                {
                    path: '/services/proverka-yuridicheskoy-chistoty-kvartiry',
                    applicationName: 'real-estate-registry',
                    availableForNotAuthorized: true,
                },
                {
                    path: '/services/guaranteed-deal',
                    applicationName: 'guaranteed-deal',
                    availableForNotAuthorized: true,
                },
                { path: '/secure-payment-service', applicationName: 'secure-payment-service' },
                { path: '/accounting', applicationName: 'accounting' },
                { path: '/contract-kit', applicationName: 'contract-kit', availableForNotAuthorized: false },
                { path: '/dbo-bro', applicationName: 'dbo-bro', availableForNotAuthorized: false },
                { path: '/helpers', applicationName: 'helpers', availableForNotAuthorized: false },
                {
                    path: '/professional-search',
                    applicationName: 'professional-search',
                    assetsUrl: '/search/manifests/zbs-search/',
                    availableForNotAuthorized: false,
                },
                {
                    path: '/offer-placement',
                    applicationName: 'offer-placement',
                    assetsUrl: '/search/manifests/offer-placement/',
                    availableForNotAuthorized: false,
                },
                {
                    path: '/favorites',
                    applicationName: 'favorites',
                    assetsUrl: '/search/manifests/favorites/',
                    availableForNotAuthorized: false,
                },
                {
                    path: '/remont/(account|manager|client|contractor|designer)',
                    applicationName: 'remont',
                    availableForNotAuthorized: false,
                },
                { path: '/remont', applicationName: 'remont-public', availableForNotAuthorized: true },
                {
                    path: '/document-configurator',
                    applicationName: 'document-configurator',
                    availableForNotAuthorized: true,
                },
                { path: '/eregistration', applicationName: 'eregistration', availableForNotAuthorized: false },
                { path: '/sdelka', applicationName: 'sdelka', availableForNotAuthorized: false },
                { path: '(/vacancies|/partners)', applicationName: 'vacancies', availableForNotAuthorized: true },
                { path: '/sbr', applicationName: 'sbr-public', availableForNotAuthorized: true },
                { path: '/developers', applicationName: 'partners-public', availableForNotAuthorized: true },
                { path: '(/advisor|/seaside)', applicationName: 'individuals-public', availableForNotAuthorized: true },
                { path: '/billing', applicationName: 'billing', availableForNotAuthorized: false },
                { path: '/vas', applicationName: 'temp-landings', availableForNotAuthorized: true },
            ],
        },
    },
    footerStore: {
        footer: {
            downloadAppQrCode: 'https://cdn.m2.ru/assets/file-upload-server/29f57c4c51c7f8de5cc93a614d562e0e.svg',
            socialLinks: [
                { name: 'ВКонтакте', icon: 'vk', href: 'https://vk.com/metr_kvadratnyy' },
                { name: 'Telegram', icon: 'telegram', href: 'https://t.me/metrkvadratny/' },
            ],
            middleLinks: [
                {
                    title: 'Частным лицам',
                    links: [
                        {
                            name: 'Новостройки',
                            href: '/moskva/novostroyki/',
                            linkGenerationId: 'new-buildings',
                            analytics: 'footerLinkNovostroyki',
                        },
                        { name: 'Ипотека', to: '/ipoteka/', analytics: 'footerLinkIpoteka' },
                        {
                            name: 'Ипотечный калькулятор',
                            to: '/ipoteka/calculator/',
                            analytics: 'footerLinkIpotechyjKalkulyator',
                        },
                        { name: 'Сделка', to: '/services/deal/', analytics: 'footerDeal' },
                        { name: 'Защита сделки', to: '/services/guaranteed-deal/', analytics: 'footerGuaranteedDeal' },
                        {
                            name: 'Проверка недвижимости',
                            to: '/services/proverka-yuridicheskoy-chistoty-kvartiry/',
                            analytics: 'footerLinkProverka',
                        },
                        { name: 'Популярное', to: '/poleznoe/', analytics: 'footerPopular' },
                    ],
                },
                {
                    title: 'Риелторам и агентствам',
                    links: [
                        {
                            name: 'Новостройки',
                            href: '/moskva/novostroyki/',
                            linkGenerationId: 'new-buildings',
                            analytics: 'footerLinkNovostroyki',
                        },
                        { name: 'Ипотека', to: '/rieltoram/ipoteka/' },
                        { name: 'Сделка', to: '/rieltoram/online-sdelka/' },
                        { name: 'Сервис безопасных расчётов', to: '/rieltoram/sbr/' },
                        { name: 'Электронная регистрация', to: '/rieltoram/elreg/' },
                        { name: 'Защита сделки', to: '/rieltoram/guaranteed-deal/' },
                        { name: 'Проверка недвижимости', to: '/rieltoram/proverka/', analytics: 'footerLinkProverka' },
                        { name: 'Мероприятия', to: '/events/' },
                        { name: 'M2Pro Новостройки', to: '/rieltoram/bonus-novostroyki/' },
                    ],
                },
            ],
            top: [
                [
                    { name: 'О компании', to: '/about/', analytics: 'footerLinkAbout' },
                    { name: 'Вакансии', to: '/vacancies/', analytics: 'footerLinkVacancies' },
                    { name: 'ИТ-деятельность', to: '/it/', analytics: 'footerLinkIT' },
                    { name: 'Помощь', to: '/support/', analytics: 'footerLinkSupport' },
                    { name: 'Наши партнёры', to: '/partners/', analytics: 'footerLinkPartners' },
                    { name: 'М2 Медиа', href: '/media/', analytics: 'footerLinkNews' },
                ],
                [
                    {
                        name: 'Правила оплаты',
                        href: '/assets/pdfs/oplata-onlain.pdf',
                        cdn: true,
                        analytics: 'footerLinkPaymentRules',
                    },
                    {
                        name: 'Закупки',
                        href: 'https://bidzaar.com/publicprofile/index/54eee136-f189-4823-8b5c-5dc58589d1d0',
                        target: '_blank',
                        analytics: 'footerLinkTender',
                    },
                    { name: 'Клиентские документы', href: '/doc/clients/', analytics: 'footerLinkSoglasheniya' },
                ],
            ],
            middle: [
                {
                    title: 'Поиск',
                    links: [{ name: 'Новостройки', href: '/moskva/novostroyki/', analytics: 'footerLinkNovostroyki' }],
                },
                {
                    title: 'Ипотека',
                    links: [{ name: 'Заявка на ипотеку', to: '/ipoteka/', analytics: 'footerLinkIpoteka' }],
                },
                {
                    title: 'Сервисы',
                    links: [
                        { name: 'Сделка', to: '/services/deal/', analytics: 'footerDeal' },
                        { name: 'Защита сделки', to: '/services/guaranteed-deal/', analytics: 'footerGuaranteedDeal' },
                        {
                            name: 'Проверка недвижимости',
                            to: '/services/proverka-yuridicheskoy-chistoty-kvartiry/',
                            analytics: 'footerLinkProverka',
                        },
                    ],
                },
                { title: 'Ремонт', links: [{ name: 'Ремонт', to: '/remont/', analytics: 'footerLinkRemont' }] },
            ],
        },
    },
    layoutStore: {
        layoutConfig: {
            layouts: [
                { ref: 'empty' },
                { ref: 'authorized', sidebar: { show: true } },
                { ref: 'lite', header: { sticky: false, filled: false, hideElementsDependOnAuthState: true } },
                { ref: 'default', header: { sticky: false, filled: false }, footer: { show: true } },
                { ref: 'authentication', header: { sticky: false, filled: false } },
            ],
            routes: [
                {
                    url: [
                        '^/login.*',
                        '^/registration.*',
                        '^/reset-password.*',
                        '^/link-registration.*',
                        '^/services/deal/join.*',
                    ],
                    layoutRef: 'authentication',
                },
                {
                    url: [
                        '^/personal-area.*',
                        '^/deal-management.*',
                        '^/management.*',
                        '^/tarif-supersdelka.*',
                        '^/admin.*',
                        '^/partner(/.*)?$',
                        '^/secure-payment-service.*',
                        '^/remont/(account|manager|client(?!/webview)|contractor|designer|reseller)',
                        '^/remont/contest(/.*)?$',
                        '^/eregistration(?!(/remote-usb-signing)).*',
                        '^/client-sign.*',
                        '^/sdelka.*',
                        '^/professional-search.*',
                        '^/offer-placement(?!(/public)).*',
                        '^/feed-statistic.*',
                        '^/favorites.*',
                        '^/saved-search.*',
                        '^/hidden-offers.*',
                        '^/account.*',
                        '^/employees.*',
                        '^/affiliated-companies.*',
                        '^/contract-kit.*',
                        '^/alternative.*',
                        '^/apartment-complex.*',
                        '^/m2-pro-commission-editor.*',
                        '^/m2-pro-commission.*',
                        '^/m2-pro(?!/presentations/.+)(/.*)?/?$',
                        '^/billing.*',
                        '^/(ipoteka|mortgage)(/partners|)/demand.*',
                        '^/(ipoteka|mortgage)/(list|dealonline).*',
                        '^/services/real-estate-registry-lawyer(/.*)?$',
                        '^/vl.*',
                        '^/suburban(/(manager|client(?!/webview)|contractor))',
                        '^/suburban-catalog.*',
                        '^/rieltor/(clients|earn|academy|baza-znanij)(/.*)?$',
                        '^/helpdesk.*',
                        '^/person.*',
                    ],
                    layoutRef: 'authorized',
                },
                {
                    url: [
                        '^/ipoteka/avito',
                        '^/ipoteka-white',
                        '^/remont/house',
                        '^/remont/client/webview',
                        '^/suburban/client/webview',
                        '^/m2-pro/presentations/.+$',
                        '^/eregistration/remote-usb-signing',
                        '^/helpers',
                        '^/deal',
                    ],
                    layoutRef: 'empty',
                },
                { url: ['^/logout.*', '^/invoice.*'], layoutRef: 'lite' },
                { url: ['.+'], layoutRef: 'default' },
            ],
            hideUseDeskRoutes: ['^/chat.*', '^/m2-pro/presentations/.+$', '^/login/vtb.*'],
            hideCookieConsentRoutes: ['^/login/vtb.*'],
        },
    },
    sidebarStore: { sidebar: { main: [], footer: [] } },
    headerStore: {
        header: {
            items: [
                {
                    id: 'DEFAULT',
                    displayName: 'Частным лицам',
                    path: '/',
                    dropdownMenu: [
                        {
                            id: '6759450a852e8ac9d214a6bb',
                            displayName: 'Новостройки',
                            url: '/moskva/novostroyki/',
                            icon: 'm2-magnifier',
                        },
                        {
                            id: '6345648ac4af4b264e895b89',
                            displayName: 'Ипотека',
                            path: '',
                            icon: 'm2-percentage-circle',
                            exact: true,
                            dropdownMenu: [
                                {
                                    id: '64e8ad903ebc9e513bbf6d5f',
                                    displayName: 'Подать заявку',
                                    path: '/ipoteka/',
                                    icon: '',
                                    exact: true,
                                },
                                {
                                    id: '64e8ada43ebc9e513bbf6d60',
                                    displayName: 'Ипотечный калькулятор',
                                    path: '/ipoteka/calculator/',
                                    icon: '',
                                },
                                {
                                    id: '65705a315216331f9d89bd97',
                                    displayName: 'Ипотека на строительство дома',
                                    path: '/ipoteka/stroitelstvo-doma/',
                                    icon: '',
                                    exact: true,
                                },
                                {
                                    id: '657059a15216331f9d89bd90',
                                    displayName: 'Семейная ипотека',
                                    path: '/ipoteka/semeinaya-ipoteka/',
                                    icon: '',
                                    exact: true,
                                },
                                {
                                    id: '657059b35216331f9d89bd91',
                                    displayName: 'Рефинансирование ипотеки',
                                    path: '/ipoteka/refinansirovanie-ipoteki/',
                                    icon: '',
                                    exact: true,
                                },
                                {
                                    id: '657059d95216331f9d89bd94',
                                    displayName: 'Ипотека с господдержкой',
                                    path: '/ipoteka/lgotnaya-s-gospodderzhkoy/',
                                    icon: '',
                                    exact: true,
                                },
                                {
                                    id: '657059fa5216331f9d89bd95',
                                    displayName: 'Ипотека на первичное жильё',
                                    path: '/ipoteka/na-zhile-na-pervichnom-rynke/',
                                    icon: '',
                                    exact: true,
                                },
                                {
                                    id: '65705a1a5216331f9d89bd96',
                                    displayName: 'Ипотека на вторичное жильё',
                                    path: '/ipoteka/na-zhile-na-vtorichnom-rynke/',
                                    icon: '',
                                    exact: true,
                                },
                            ],
                        },
                        {
                            id: '6345651dc4af4b264e895b8c',
                            displayName: 'Сделка',
                            path: '/services/deal/',
                            icon: 'house-plus',
                        },
                        {
                            id: '669a480e4798dc4baa83f4a5',
                            displayName: 'Проверка',
                            path: '/services/proverka-yuridicheskoy-chistoty-kvartiry/',
                            icon: '',
                        },
                        {
                            id: '669a48614798dc4baa83f4ac',
                            displayName: 'Защита сделки',
                            path: '/services/guaranteed-deal/',
                            icon: '',
                        },
                    ],
                },
                {
                    id: 'REALTORS',
                    displayName: 'Риелторам и агентствам',
                    path: '/rieltoram/',
                    icon: '',
                    dropdownMenu: [
                        {
                            id: '67594246852e8ac9d214a4fd',
                            displayName: 'Новостройки',
                            url: '/moskva/novostroyki/',
                            icon: 'm2-magnifier',
                        },
                        {
                            id: '634569648a92e72824a8807a',
                            displayName: 'Ипотека',
                            path: '/rieltoram/ipoteka/',
                            icon: 'm2-percentage-circle',
                        },
                        {
                            id: '634569838a92e72824a8807c',
                            displayName: 'Сделка',
                            path: '/rieltoram/online-sdelka/',
                            icon: 'note-sign',
                        },
                        {
                            id: '6345699b8a92e72824a8807e',
                            displayName: 'Сервисы',
                            path: '',
                            icon: 'm2-star',
                            dropdownMenu: [
                                {
                                    id: '634569ac8a92e72824a88080',
                                    displayName: 'Сервис безопасных расчётов',
                                    path: '/rieltoram/sbr/',
                                    icon: '',
                                },
                                {
                                    id: '634569bd8a92e72824a88082',
                                    displayName: 'Электронная регистрация',
                                    path: '/rieltoram/elreg/',
                                    icon: '',
                                },
                                {
                                    id: '634569d68a92e72824a88084',
                                    displayName: 'Защита сделки',
                                    path: '/rieltoram/guaranteed-deal/',
                                    icon: '',
                                },
                                {
                                    id: '634569f58a92e72824a88086',
                                    displayName: 'M2Pro Новостройки',
                                    path: '/rieltoram/m2pro-novostroyki/',
                                    icon: '',
                                },
                                {
                                    id: '6447cc6217809f0048b89a1f',
                                    displayName: 'Зарубежная недвижимость',
                                    path: '/rieltoram/zarubezhom/',
                                    icon: '',
                                },
                                {
                                    id: '63456a148a92e72824a88088',
                                    displayName: 'Проверка недвижимости',
                                    path: '/rieltoram/proverka/',
                                    icon: '',
                                },
                            ],
                        },
                        {
                            id: '63456a3e8a92e72824a8808a',
                            displayName: 'Обучение',
                            path: '',
                            icon: 'calendar',
                            dropdownMenu: [
                                {
                                    id: '668fef213dd22e52f8ecc8a1',
                                    displayName: 'База знаний',
                                    path: '/rieltoram/baza-znanij/',
                                    icon: '',
                                },
                                {
                                    id: '668feeb43dd22e52f8ecc88c',
                                    displayName: 'Мероприятия',
                                    path: '/events/',
                                    icon: '',
                                },
                                {
                                    id: '66fa99bb335f3685d8e5a933',
                                    displayName: 'Тренинги',
                                    path: '/rieltoram/online-training/',
                                    icon: '',
                                },
                                {
                                    id: '676ad2427b8ad3e5328f2f86',
                                    displayName: 'Курс: ипотека',
                                    path: '/rieltoram/kurs-ipoteka/',
                                    icon: '',
                                },
                                {
                                    id: '676ad27e7b8ad3e5328f2fb6',
                                    displayName: 'Курс: налоги и законы',
                                    path: '/rieltoram/kurs-nalogi-i-zakony/',
                                    icon: '',
                                },
                                {
                                    id: '676ad2ba7b8ad3e5328f2ff3',
                                    displayName: 'Курс: новостройки',
                                    path: '/rieltoram/kurs-novostroiki/',
                                    icon: '',
                                },
                            ],
                        },
                        {
                            id: 'rieltoram-rating',
                            displayName: 'Рейтинг агентств',
                            path: '/rieltoram/rating/',
                            icon: '',
                        },
                    ],
                },
                {
                    id: 'PARTNERS',
                    displayName: 'Партнёрам',
                    path: '/partners/',
                    icon: '',
                    dropdownMenu: [
                        { id: '63456b048a92e72824a88095', displayName: 'Наши партнёры', path: '/partners/', icon: '' },
                        {
                            id: '646340fecd286f0048fb0868',
                            displayName: 'Амбассадоры',
                            url: 'https://am2ssador.m2.ru/',
                            icon: 'lightning-circle',
                            target: '_blank',
                        },
                    ],
                },
            ],
            partners: {
                samolet: {
                    id: 'samolet',
                    logo: 'https://cdn.m2.ru/assets/file-upload-server/79d4b22c91a9e7476efcd27c1c19305a.svg\t',
                    logoWhite: 'https://cdn.m2.ru/assets/file-upload-server/f56694b65eee2c805b5f384aa029a70a.svg\t',
                    url: '/samolet/?erid=sKjnGhd4KprWDTakBBthFWQpQEht771BNCSQFPFNiSNzUkK',
                    regionIds: [9513, 3, 108],
                    analyticsDataClick: 'msk-special-project-logo-click',
                },
            },
            personalButtons: {},
            loginPaths: {
                default: { id: 'default-login', url: '/login/' },
                client: { id: 'login-client', url: '/login/client/' },
                professional: { id: 'login-professional', url: '/login/professional/' },
                partner: { url: '/login/partner/', id: 'login-partner' },
            },
        },
    },
};

import { HeaderTabItem } from '@vtblife/layout-config/types';
import React from 'react';
import cn from 'classnames';

import { MenuItemBase } from '../../menu-item-base/menu-item-base';
import styles from './dropdown-menu-item.module.css';
import { comparePathname } from '../../utils';

export interface DropdownMenuItemProps {
    pathname?: string;
    item: HeaderTabItem & {
        freshCount?: number;
    };
    isMainMenu?: boolean;
    onClick?: () => void;
    dataTest?: string;
}

export const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
    item,
    pathname,
    isMainMenu,
    onClick,
    dataTest,
}) => {
    const { path, url, displayName, id, isSidebarEl } = item;
    const isActive = comparePathname(pathname, item);
    return (
        <MenuItemBase
            dataTest={dataTest}
            className={cn(styles.dropdownMenuItem, {
                [styles.dropdownMenuItemActive]: isActive,
                [styles.dropdownMenuItemMain]: isMainMenu,
            })}
            path={path}
            url={url}
            onClick={onClick}
            isActive={isActive}
            id={id}
            isSidebarEl={isSidebarEl}
        >
            <span>
                {displayName}
                {item.freshCount ? (item.freshCount >= 99 ? ' 99+' : ` +${item.freshCount}`) : null}
            </span>
        </MenuItemBase>
    );
};
